<template>
  <div>
    Tabela aqui
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBTooltip,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import store from "@/store/index";
import api from '../service/api.js'

export default {
  components: {
    BCardText,
    VBTooltip,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    ToastificationContent,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    let self = this;
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }

    if (Cookies.get("token_api") == undefined) {
      top.location.href = "/login";
    }
    if(store.state.refresh_interval == ''){
      store.state.refresh_interval = setInterval(
      api.refresh_token,
        120000
      );
      store.state.refresh_interval = true;
    }


    self.initialRequests();
  },

  data() {
    return {
      
    };
  },
  methods: {
    async initialRequests(){
      let device_param = "&deviceId=" + store.state.selected_device;
      let data_response = await api.getAllDatas(device_param);
    }
  },
};
</script>

<style>

</style>

